<template lang="html">
  <div id="place-page">

    <section class="main-section">
      <div class="container">
        <div class="row">
          <div class="col-12 mb-3 col-title">
            <h1 class="title">Zonas y mesas</h1>

            <!-- <div class="box-tabs">
              <div class="tabs">
                <div class="tab" v-bind:class="{ active : selectedTab == 'gal' }" @click="selectedTab = 'gal'">Galería</div>
                <div class="tab" v-bind:class="{ active : selectedTab == 'inf' }" @click="selectedTab = 'inf'">Información</div>
              </div>
            </div> -->
          </div>
        </div>

        <!-- Información -->
        <div class="row">
          <div class="col-12 col-info">
            <div class="box-image"
              v-for="(image, imageIndex) in gallery"
              :key="imageIndex"
              @click="index = imageIndex">
              <img src="public/images/pages/gallery/sede.svg">
            </div>

            <div class="box-table">
              <div class="table-responsive-md">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">ZONA</th>
                      <th scope="col">MOBILIARIO</th>
                      <th scope="col">OPCIONES DE LUGARES</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">VIP STAGE</th>
                      <td>MESA VIP</td>
                      <td>8</td>
                    </tr>
                    <tr>
                      <th scope="row">DIAMANTE</th>
                      <td>MESA VIP</td>
                      <td>1 a 8</td>
                    </tr>
                    <tr>
                      <th scope="row">VIP BLACK</th>
                      <td>MESA VIP</td>
                      <td>1 a 10</td>
                    </tr>
                    <tr>
                      <th scope="row">PLATINO IZQ</th>
                      <td>MESA VIP</td>
                      <td>1 a 8</td>
                    </tr>
                    <tr>
                      <th scope="row">PLATINO DER</th>
                      <td>MESA VIP</td>
                      <td>1 a 8</td>
                    </tr>
                    <tr>
                      <th scope="row">ORO A</th>
                      <td>MESA VIP</td>
                      <td>1 a 8</td>
                    </tr>
                    <tr>
                      <th scope="row">ORO B</th>
                      <td>MESA VIP</td>
                      <td>1 a 8</td>
                    </tr>
                    <tr>
                      <th scope="row">ORO C</th>
                      <td>MESA VIP</td>
                      <td>1 a 6</td>
                    </tr>
                    <tr>
                      <th scope="row">PLATA DER</th>
                      <td>MESA ELEGANCE</td>
                      <td>1 a 2</td>
                    </tr>
                    <tr>
                      <th scope="row">PLATA DER</th>
                      <td>MESA ALTA</td>
                      <td>1 a 4</td>
                    </tr>
                    <tr>
                      <th scope="row">PLATA IZQ</th>
                      <td>MESA ALTA</td>
                      <td>1 a 4</td>
                    </tr>
                    <tr>
                      <th scope="row">PREMIM A</th>
                      <td>MESA VIP</td>
                      <td>1 a 8</td>
                    </tr>
                    <tr>
                      <th scope="row">PREMIUM B</th>
                      <td>MESA ELEGANCE</td>
                      <td>1 a 4</td>
                    </tr>
                    <tr>
                      <th scope="row">BRONCE A</th>
                      <td>MESA BAJA</td>
                      <td>1 a 5</td>
                    </tr>
                    <tr>
                      <th scope="row">BRONCE B</th>
                      <td>MESA BAJA</td>
                      <td>1 a 4</td>
                    </tr>
                    <tr>
                      <th scope="row">ROJA – AZUL</th>
                      <td>SILLA NUMERADA</td>
                      <td>1</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <!-- <div class="box-descr">
              <h5>ESPECIFICACIONES DE LAS MESAS POR ZONAS EN SEDE STAGE</h5>
              <br />

              <h6>ZONA STAGE: “Se debe comprar la mesa completa”. 8 Personas.</h6>
              <ul>
                <li>
                  Mesa completa: Incluye 8 espacios en tu mesa (4 Taburetes con respaldo y un Sillón con 4 lugares).
                </li>
                <li>
                  Incluye 2 lugares de estacionamiento privado con Valet Parking. Tu vehículo será recibido afuera de las instalaciones del lugar, presentando tu boleto digital previamente adquirido.<br />
                  Además, si así lo deseas, tienes la opción de agregar hasta 2 boletos adicionales a tu orden por mesa sin lugar asignado*, la compra de lugares adicionales se realiza a través de la siguiente línea de teléfono: 3333179287
                </li>
              </ul>
              <br />

              <h6>ZONA DIAMANTE: “Se debe comprar la mesa completa” 8 Personas.</h6>
              <ul>
                <li>
                  Mesa completa: Incluye 8 espacios en tu mesa (4 Taburetes con respaldo y un Sillón con 4 lugares).
                </li>
                <li>
                  Incluye 2 lugares de estacionamiento privado con Valet Parking. Tu vehículo será recibido afuera de las instalaciones del lugar presentando tu boleto digital previamente adquirido.
                </li>
              </ul>

              <br />
              <h6>ZONA BLACK: “Se debe comprar la mesa completa” 6 Y 10 Personas.</h6>
              <ul>
                <li>
                  Mesa completa: Incluye 6 espacios en tu mesa (2 Taburetes con respaldo y un Sillón con 4 lugares).
                </li>
                <li>
                  Mesa completa: Incluye 10 espacios en tu mesa (2 Taburetes y 2 sillones con 4 lugares cada uno)
                </li>
                <li>
                  Incluye 2 lugares de estacionamiento privado con Valet Parking. Tu vehículo será recibido afuera de las instalaciones del lugar, presentando tu boleto digital previamente adquirido.
                </li>
              </ul>
              <br />

              <h6>ZONA PLATINO IZQUIERDA Y DERECHA: “Se puede comprar la mitad de la mesa o la mesa completa” Para 4 / 8 personas.</h6>
              <ul>
                <li>
                  Mesa completa: Incluye 8 espacios en tu mesa (4 Taburetes con respaldo y un Sillón con 4 lugares).
                </li>
                <li>
                  Mitad de mesa p/ 4 personas: Incluye 4 espacios en tu mesa (2 Taburetes con respaldo, Sillón compartido con 2 asientos).
                </li>
                <li>
                  Un lugar de estacionamiento privado con Valet Parking, en la compra de la mesa completa (8 asientos). Tu vehículo será recibido afuera de las instalaciones, presentando tu boleto digital previamente adquirido.
                </li>
              </ul>
              <br />

              <h5>CARACTERÍSTICAS Y ESPECIFICACIONES DE LAS MESAS POR ZONAS</h5>
              <h6>ZONA ORO A Y B: En caso de no comprar la mesa completa se tendría que compartir con más personas” 6 Y 8 PERSONAS</h6>
              <ul>
                <li>
                  Las mesas P/ 8 se pueden comprar mínimo 4 lugares y en múltiplos de 4”.
                </li>
                <li>
                  Mesa completa: Incluye P/8 espacios en tu mesa (4 Taburetes con respaldo y un Sillón con 4 lugares).
                </li>
                <li>
                  Mitad de mesa P/4 personas: Incluye 4 espacios en tu mesa (2 Taburetes con respaldo, Sillón compartido con 2 asientos).
                </li>
                <li>
                  Mesa de P/6 Se debe comprar la mesa completa incluye (2 Taburetes con respaldo y un Sillón con 4 lugares).
                </li>
              </ul>
              <br />

              <h6>ZONA ORO C: “En caso de no comprar la mesa completa se tendría que compartir con más personas “Las mesas de 6 personas se pueden comprar en múltiplos de 2 lugares, pero será compartida la mesa en general”.</h6>
              <ul>
                <li>Mesa completa: Incluye P/6 espacios en tu mesa (2 Taburetes con respaldo y un Sillón con 4 lugares).</li>
                <li>Mesa P/2 personas: Incluye 2 espacios en tu mesa (La mesa se comparte con otras personas y también se comparten los espacios entre las mismas personas).</li>
              </ul>
              <br />

              <h6>ZONA PLATA IZQUIERDA Y DERECHA: “Se debe comprar la mesa alta o la mesa baja completa”</h6>
              <ul>
                <li>Mesa alta P/ 4 personas: Incluye 4 espacios en tu mesa (Mesa alta con 4 cómodos Bancos).</li>
                <li>Mesa baja P/ 2 personas: Incluye 2 espacios en tu mesa (2 Sillones Elegance de alto Confort).</li>
              </ul>
              <br />

              <h6>ZONA PLATA CENTRO: “Se debe comprar la mesa completa”</h6>
              <ul>
                <li>Mesa baja P/ 2 personas: Incluye 2 espacios en tu mesa (2 Sillones Elegance de alto Confort).</li>
              </ul>
              <br />

              <h6>ZONA PREMIUM A: “Se puede comprar la mitad de la mesa o la mesa completa”</h6>
              <ul>
                <li>Mesa completa: Incluye P/8 espacios en tu mesa (4 Taburetes con respaldo y un Sillón con 4 lugares).</li>
                <li>Mesa completa: Incluye P/8 espacios en tu mesa (4 Taburetes con respaldo y un Sillón con 4 lugares).</li>
              </ul>
              <br />

              <h6>ZONA PREMIUM B: “Se debe comprar la mesa completa”</h6>
              <ul>
                <li>Mesa baja P/ 4 personas: Incluye 4 espacios en tu mesa (4 Sillones Elegance de alto Confort y una mesa redonda para bebidas).</li>
              </ul>
              <br />

              <h6>ZONA BRONCE A Y B: “Se debe comprar la mesa completa”</h6>
              <ul>
                <li>Mesa completa p/ 5 personas: Incluye 5 espacios en tu mesa (5 Sillas plegadizas).</li>
                <li>Mesa completa p/ 4 personas: Incluye 4 espacios en tu mesa (4 Sillas plegadizas).</li>
                <li>Mesa completa p/ 2 personas: Incluye 2 espacios en tu mesa (2 Sillas plegadizas).</li>
              </ul>
              <br />

              <h6>ZONA ROJA Y AZUL</h6>
              <p>
                Las sillas se venden individualmente y vienen numeradas por silla y fila.
              </p>
              <br />

              <h5>EXISTEN 4 PUERTAS DE INGRESO AL RECINTO:</h5>
              <ol>
                <li>ACCESO VIP EXPERIENCE </li>
                <li>ACCESO A/ ROCK STAR</li>
                <li>ACCESO B / PUERTA 1</li>
                <li>ACCESO B / PUERTA 2 </li>
              </ol>
              <br />

              <h5>ESTACIONAMIENTO.</h5>
              <p>Existen 2 formas de estacionar tu vehículo:</p>
              <ol>
                <li>Por medio del valet parking propio que recibirá tu vehículo afuera del recinto, siempre y cuando la mesa que hayas adquirido tenga derecho a boleto de valet parking.</li>
                <li>Puedes dejar tu vehículo en la Plaza Galerias Santa Anita que se encuentra a un lado del recinto, tenemos un acuerdo comercial muy accesible en donde tu carro estará en estacionamiento privado a menos de 100 metros del lugar.</li>
              </ol>
            </div> -->
          </div>
        </div>
      </div>
    </section>

    <CoolLightBox
      :items="gallery"
      :index="index"
      :effect="'fade'"
      @close="index = null">
    </CoolLightBox>

  </div>
</template>

<script>
import CoolLightBox from 'vue-cool-lightbox'; // https://vue-cool-lightbox.lucaspulliese.com/
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';
export default {
  components: {
    CoolLightBox,
  },

  data() {
    return {
      gallery: [
        'public/images/pages/gallery/sede.png',
      ],
      index: null,
    }
  }
}
</script>
