<template lang="html">
  <div id="place-page">

    <section class="main-section">
      <div class="container oversized-container">
        <div class="row">
          <div class="col-12 col-title">
            <h1 class="title">Galería</h1>

            <!-- <div class="box-tabs">
              <div class="tabs">
                <div class="tab" v-bind:class="{ active : selectedTab == 'gal' }" @click="selectedTab = 'gal'">Galería</div>
                <div class="tab" v-bind:class="{ active : selectedTab == 'inf' }" @click="selectedTab = 'inf'">Información</div>
              </div>
            </div> -->
          </div>
        </div>

        <!-- Galería -->
        <div class="row">
          <div class="col-md-6 col-xl-4 col-image"
            v-for="(image, imageIndex) in gallery"
            :key="imageIndex"
            @click="index = imageIndex">
            <div class="t-250 box-image">
              <div class="placed-backg img" v-bind:style="{ backgroundImage: 'url('+image+')' }"></div>
            </div>
          </div>
        </div>
        <!--  -->
      </div>

      <CoolLightBox
        :items="gallery"
        :index="index"
        :effect="'fade'"
        @close="index = null">
      </CoolLightBox>
    </section>

  </div>
</template>

<script>
import CoolLightBox from 'vue-cool-lightbox'; // https://vue-cool-lightbox.lucaspulliese.com/
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';
export default {
  components: {
    CoolLightBox,
  },

  data() {
    return {
      gallery: [
        'public/images/pages/gallery/image-1.jpg',
        'public/images/pages/gallery/image-2.jpg',
        'public/images/pages/gallery/image-3.jpg',
        'public/images/pages/gallery/image-4.jpg',
        'public/images/pages/gallery/image-5.jpg',
        'public/images/pages/gallery/image-6.jpg',
        'public/images/pages/gallery/image-7.jpg',
        'public/images/pages/gallery/image-8.jpg',
        'public/images/pages/gallery/image-9.jpg',
        'public/images/pages/gallery/image-10.jpg',
        'public/images/pages/gallery/image-11.jpg',
        'public/images/pages/gallery/image-12.jpg',
        'public/images/pages/gallery/image-13.jpg',
        'public/images/pages/gallery/image-14.jpg',
        'public/images/pages/gallery/image-15.jpg',
        'public/images/pages/gallery/image-16.jpg',
        'public/images/pages/gallery/image-17.jpg',
        'public/images/pages/gallery/image-18.jpg',
        'public/images/pages/gallery/image-19.jpg',
        'public/images/pages/gallery/image-20.jpg',
        'public/images/pages/gallery/image-21.jpg',
        'public/images/pages/gallery/image-22.jpg',
        'public/images/pages/gallery/image-23.jpg',
        'public/images/pages/gallery/image-24.jpg',
        'public/images/pages/gallery/image-25.jpg',
        'public/images/pages/gallery/image-26.jpg',
        'public/images/pages/gallery/image-27.jpg',
        'public/images/pages/gallery/image-28.jpg',
        'public/images/pages/gallery/image-29.jpg',
        'public/images/pages/gallery/image-30.jpg',
        'public/images/pages/gallery/image-31.jpg',
        'public/images/pages/gallery/image-32.jpg',
        'public/images/pages/gallery/image-33.jpg',
        'public/images/pages/gallery/image-34.jpg',
        'public/images/pages/gallery/image-35.jpg',
      ],
      index: null,
    }
  }
}
</script>
