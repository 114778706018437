<template lang="html">
  <div id="about-us-page">

    <section class="main-section">
      <i class="bg"></i>

      <div class="container oversized-container">
        <div class="row">
          <div class="offset-lg-6 col-lg-6 col-xl-6 col-contant">
            <h1 class="title">Nosotros</h1>

            <p>
              Somos un lugar vanguardista que promovemos la música, el arte, la cultura y el entretenimiento para todos los públicos.<br />
              Guadalajara, Jalisco - nos ubicamos en el corazón del municipio de Tlajomulco de Zúñiga, en Jalisco, ahí nace este nuevo referente en la escena de los espectáculos en México: SEDE STAGE. Este impresionante SHOW CENTER,  y nos posicionamos como uno de los espacios para eventos más destacados en el sur de la ciudad, prometemos llevar la experiencia de los conciertos y shows de entretenimiento a un siguiente nivel con una experiencia vip inigualable.
            </p>
            <p class="mt-3">
              Sede Stage ha sido diseñado pensando en ofrecer una experiencia VIP a todos los asistentes. Sus cómodas instalaciones y la cercanía con el escenario permiten disfrutar de los eventos con un nivel de intimidad y conexión sin precedentes. Además, SEDE STAGE ofrece múltiples zonas VIP, asegurando una experiencia inigualable para los espectadores más exigentes.
            </p>
            <p class="mt-3">
              SEDE STAGE no solo se limitará a la realización de conciertos y shows, sino que también estará disponible para otro tipo de eventos, como posadas empresariales, conferencias, peleas de box, congresos, eventos privados, festivales de música electrónica, festivales infantiles, convenciones y mucho más. Es un espacio polivalente que se adapta a cualquier necesidad y ofrece una plataforma ideal para promocionar tu imagen o negocio.
            </p>
            <p class="mt-3">
              La venta de boletos para los eventos de SEDE STAGE se realizará a través de la reconocida plataforma boletomovil.com, facilitando así el acceso a todos aquellos que deseen vivir una experiencia inolvidable.
            </p>
            <p class="mt-2">
              Para más información y actualizaciones sobre los próximos eventos, puedes seguir a SEDE STAGE en sus redes sociales.
            </p>
            <p>
              Prepárate para descubrir un nuevo concepto de entretenimiento en Guadalajara con una experiencia vip en el mejor show center de Jalisco.
            </p>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
}
</script>
