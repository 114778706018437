<template lang="html">
  <div id="text-page">
    <div class="container">
      <h1 class="mb-2 page-title">Recomendaciones y reglamento</h1>

      <h5>PUNTUALIDAD</h5>
      <p>
        Le recomendamos llegar con anticipación al lugar. Las puertas se abren dos horas antes de que inicie el evento.
      </p>
      <br />

      <h5>ACCESIBILIDAD</h5>
      <p>
        Las personas con alguna discapacidad pueden acudir a nuestras taquillas para que le sea asignado el mejor lugar disponible en términos de seguridad y visibilidad. Tenemos acceso por rampa directo en  la puerta 1.
      </p>
      <br />

      <h5>VISIBILIDAD LIMITADA</h5>
      <p>
        En algunos lugares de la zona AZUL es posible que haya algún elemento de la producción que obstruya parcialmente la visión.
      </p>
      <br />

      <h5>INGRESO DE MENORES</h5>
      <p>
        El ingreso a la sala es a partir de los 15 años en adelante pagando su boleto y en la compañía de un adulto, o se destinara una sección exclusiva para menores sin venta de alcohol, esta sección puede variar por concierto.
      </p>
      <br />

      <h5>OBJETOS PROHIBIDOS</h5>
      <p>
        No se permite el ingreso con tabletas electrónicas, cámaras fotográficas, ni de video. No se permite el ingreso con botellas y envases de ningún tipo. No se permite el ingreso con banderas y letreros que puedan obstaculizar la vista de los asistentes. No se permite el ingreso con flores o globos. No se permiten mascotas (a excepción de perros guías). Se prohíbe el ingreso de armas y objetos punzocortantes. En caso de ser sorprendido con estos objetos se pondrá a disposición de las autoridades correspondientes.
      </p>
      <br />

      <h5>ÁREA LIBRE DE HUMO</h5>
      <p>
        No se permite fumar dentro de las instalaciones.
      </p>
      <br />

      <h5>ACTIVIDADES PROHIBIDAS</h5>
      <p>
        No se permite cargar en hombros por seguridad de los asistentes. Se prohíbe lanzar objetos de cualquier tipo al escenario, la persona que sea sorprendida será retirada del inmueble de inmediato.
      </p>
      <br />

      <h5>SUSTANCIAS PROHIBIDAS</h5>
      <p>
        Se prohíbe el ingreso de bebidas alcohólicas, drogas y estupefacientes. En caso de ser sorprendido con alguna de ellas se pondrá a disposición de las autoridades correspondientes.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      content: '',
    }
  },

  methods: {
    getContent: function(){
      axios.get(tools.url('/privacidad')).then((response)=>{
        this.content = response.data;;
      }).catch((error)=>{
        console.log(error);
      })
    }
  },

  beforeMount(){
    // this.getContent();
  }
}
</script>
