import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

//Componentes
//import Login from './components/admin/Login.vue';

const page="./components/page/";

const MyRouter = new VueRouter({
  	routes:[
      { path: '/', name: 'homePage', component: require(page+'home.vue').default },
      { path: '/empresa', component: require(page+'about-us/about-us.vue').default, meta:{ title:"Empresa" } },
      { path: '/preguntas-frecuentes', component: require(page+'text-pages/faqs.vue').default, meta:{ title:"Preguntas frecuentes" } },
      { path: '/zonas-y-mesas', component: require(page+'place/info.vue').default, meta:{ title:"Zonas y mesas" } },
      { path: '/galeria', component: require(page+'place/gallery.vue').default, meta:{ title:"Galería" } },
	    { path: '/contacto', component: require(page+'contact/index.vue').default, meta:{ title:"Contacto" } },

      { path: '/aviso-de-privacidad', component: require(page+'text-pages/aviso.vue').default, meta:{title:"Aviso de privacidad"} },
      { path: '/reglamento', component: require(page+'text-pages/reglamento.vue').default, meta:{title:"Reglamento"} },

	    // { path: '/checkout', component: require(page+'checkout.vue').default, meta:{title:"Checkout"}},
	  ]
});

MyRouter.beforeEach((to, from, next) => {
	window.scrollTo(0,0);
	if(window.app.__vue__ && window.app.__vue__.$refs.loadingBar){
		window.app.__vue__.$refs.loadingBar.start();
	}
	next();
});

MyRouter.afterEach((to, from) => {

	if(window.app.__vue__ && window.app.__vue__.$refs.loadingBar){
		setTimeout(()=>{
			window.app.__vue__.$refs.loadingBar.done();
		},500);
	}


});

//Titulos del website
import VueDocumentTitlePlugin from "vue-document-title-plugin";
Vue.use(VueDocumentTitlePlugin, MyRouter,
	{ defTitle: "SEDE STAGE", filter: (title)=>{ return title+" - SEDE STAGE"; } }
);

// export {routes};
export default MyRouter;
