<template lang="html">
  <div id="home-page">

    <section class="banner-section" data-aos="fade">
      <swiper class="swiper desktop" :options="bannersOptions">
        <swiper-slide v-for="(b, bhInx) in banners.desktop" :key="'bhInx-'+bhInx">
          <div class="placed-backg box" v-bind:style="{ backgroundImage: 'url('+b.imageUrl+')' }">
            <img src="public/images/pages/home/banner.png">
          </div>
        </swiper-slide>

        <!-- <div class="swiper-pagination" slot="pagination"></div> -->
      </swiper>

      <swiper class="swiper mobile" :options="bannersOptions">
        <swiper-slide v-for="(b, bhmInx) in banners.mobile" :key="'bhmInx-'+bhmInx">
          <div class="placed-backg box" v-bind:style="{ backgroundImage: 'url('+b.imageUrl+')' }">
            <img src="public/images/pages/home/banner-m.png">
          </div>
        </swiper-slide>

        <!-- <div class="swiper-pagination" slot="pagination"></div> -->
      </swiper>
    </section>

    <section class="container oversized-container events-section">
      <div class="row justify-content-center">
        <div class="col-12 col-top-title">
          <h2>Próximos eventos</h2>
        </div>

        <div class="col-md-6 col-xl-4 col-event-sample-1" v-for="(e, ehInx) in events" :key="'ehInx-'+ehInx">
          <a class="_box_" @click="showEventModal(ehInx)">
            <div class="box-image">
              <img class="placed-backg" src="public/images/pages/home/event.png" v-bind:style="{ backgroundImage: 'url('+e.imageUrl+')' }">
              <div class="bubble available" v-if="e.available == true">Comprar boletos</div>
              <div class="bubble unavailable" v-else>Agotado</div>
            </div>

            <div class="box-descr">
              <div class="col-12 col-place">{{ e.place }}</div>
              <div class="col-12 col-date">{{ e.date }}, {{ e.hour }}.</div>

              <div class="col-12 col-band">{{ e.name }}</div>
              <!-- <div class="col-12 col-price">$820.00 - $1,394.00 MXN</div> -->
            </div>
          </a>
        </div>
      </div>
    </section>

    <section class="sponsors-section">
      <div class="container oversized-container" v-if="false">
        <h5 class="title">Patrocinadores</h5>

        <div class="row">
          <div class="col-lg-9 mx-auto">
            <swiper class="swiper" :options="sponsorsOptions">
              <swiper-slide v-for="(l, lInx) in sponsors" :key="'lInx-'+lInx">
                <div class="box-image">
                  <div class="image">
                    <img :src="l.imageUrl">
                  </div>
                </div>
              </swiper-slide>

              <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
          </div>
        </div>
      </div>
    </section>

    <section class="note-section">
      <h5>La nueva Sede de los grandes espectáculos.</h5>
    </section>

    <b-modal modal-class="modal-event" ref="modal-event" :size="(modalEvent.size == 'xl') ? 'xl' : '' " title="" hide-footer>
      <div class="row no-gutters">
        <div class="col col-basic-info">
          <div class="box-video" v-if="modalEvent.videoUrl">
            <iframe :src="convertVideoID(modalEvent.videoUrl)" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          </div>
          <div class="box-image" v-if="modalEvent.imageUrl && modalEvent.videoUrl == null">
            <img :src="modalEvent.imageUrl">
          </div>

          <div class="box-text">
            <h6 class="title">{{ modalEvent.name }}</h6>
            <h6 class="date">{{ modalEvent.date }} {{ modalEvent.hour }}</h6>
            <div class="text-justify" v-html="modalEvent.descr"></div>
            <a class="t-250 btn-s1 bg-green" target="_blank" v-if="modalEvent.url != null" :href="modalEvent.url">Comprar boletos</a>
          </div>
        </div>

        <div class="col col-table">
          <!-- Mariachi Vargas -->
          <div class="table-responsive" v-if="modalEvent.eventNum == 1">
            <table class="table">
              <thead>
                <tr>
                  <th>Zona</th>
                  <th>Mobiliario</th>
                  <th>Opciones de lugares</th>
                  <th>Cada boleto</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>VIP STAGE</td>
                  <td>MESA VIP</td>
                  <td>8</td>
                  <td>$2,599</td>
                </tr>
                <tr>
                  <td>DIAMANTE</td>
                  <td>MESA VIP</td>
                  <td>1 a 8</td>
                  <td>$2,390</td>
                </tr>
                <tr>
                  <td>VIP BLACK</td>
                  <td>MESA VIP</td>
                  <td>1 a 10</td>
                  <td>$1,950</td>
                </tr>
                <tr>
                  <td>PLATINO IZQ</td>
                  <td>MESA VIP</td>
                  <td>1 a 8</td>
                  <td>$2,199</td>
                </tr>
                <tr>
                  <td>PLATINO DER</td>
                  <td>MESA VIP</td>
                  <td>1 a 8</td>
                  <td>$2,199</td>
                </tr>
                <tr>
                  <td>ORO A</td>
                  <td>MESA VIP</td>
                  <td>1 a 8</td>
                  <td>$1,950</td>
                </tr>
                <tr>
                  <td>ORO B</td>
                  <td>MESA VIP</td>
                  <td>1 a 8</td>
                  <td>$1,799</td>
                </tr>
                <tr>
                  <td>ORO C</td>
                  <td>MESA VIP</td>
                  <td>1 a 6</td>
                  <td>$1,699</td>
                </tr>
                <tr>
                  <td>PLATA DER</td>
                  <td>MESA ELEGANCE</td>
                  <td>1 a 2</td>
                  <td>$1,450</td>
                </tr>
                <tr>
                  <td>PLATA DER</td>
                  <td>MESA ALTA</td>
                  <td>1 a 4</td>
                  <td>$1,450</td>
                </tr>
                <tr>
                  <td>PLATA IZQ</td>
                  <td>MESA ALTA</td>
                  <td>1 a 4</td>
                  <td>$1,450</td>
                </tr>
                <tr>
                  <td>PREMIUM A</td>
                  <td>MESA VIP</td>
                  <td>1 a 8</td>
                  <td>$1,750</td>
                </tr>
                <tr>
                  <td>PREMIUM B</td>
                  <td>MESA ELEGANCE</td>
                  <td>1 a 4</td>
                  <td>$1,650</td>
                </tr>
                <tr>
                  <td>BRONCE A</td>
                  <td>MESA BAJA</td>
                  <td>1 a 5</td>
                  <td>$1,299</td>
                </tr>
                <tr>
                  <td>BRONCE B</td>
                  <td>MESA BAJA</td>
                  <td>1 a 4</td>
                  <td>$1,199</td>
                </tr>
                <tr>
                  <td>ROJA - AZUL</td>
                  <td>SILLA NUMERADA</td>
                  <td>1</td>
                  <td>$599 A $799</td>
                </tr>
                <tr class="website">
                  <td colspan="4">WWW.BOLETOMOVIL.COM</td>
                </tr>
              </tbody>
            </table>
          </div>
          <!--  -->

          <!-- Fiesta Sinaloense -->
          <div class="table-responsive" v-if="modalEvent.eventNum == 2">
            <table class="table">
              <thead>
                <tr>
                  <th>Zona</th>
                  <th>Mobiliario</th>
                  <th>Opciones de lugares</th>
                  <th>Cada boleto</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>VIP STAGE</td>
                  <td>MESA VIP</td>
                  <td>8</td>
                  <td>$2,950</td>
                </tr>
                <tr>
                  <td>DIAMANTE</td>
                  <td>MESA VIP</td>
                  <td>1 a 8</td>
                  <td>$2,890</td>
                </tr>
                <tr>
                  <td>VIP BLACK</td>
                  <td>MESA VIP</td>
                  <td>1 a 10</td>
                  <td>$2,890</td>
                </tr>
                <tr>
                  <td>PLATINO IZQ</td>
                  <td>MESA VIP</td>
                  <td>1 a 8</td>
                  <td>$2,650</td>
                </tr>
                <tr>
                  <td>PLATINO DER</td>
                  <td>MESA VIP</td>
                  <td>1 a 8</td>
                  <td>$2,650</td>
                </tr>
                <tr>
                  <td>ORO A</td>
                  <td>MESA VIP</td>
                  <td>1 a 8</td>
                  <td>$2,690</td>
                </tr>
                <tr>
                  <td>ORO B</td>
                  <td>MESA VIP</td>
                  <td>1 a 8</td>
                  <td>$2,590</td>
                </tr>
                <tr>
                  <td>ORO C</td>
                  <td>MESA VIP</td>
                  <td>1 a 6</td>
                  <td>$2,390</td>
                </tr>
                <tr>
                  <td>PLATA DER</td>
                  <td>MESA ELEGANCE</td>
                  <td>1 a 2</td>
                  <td>$1,990</td>
                </tr>
                <tr>
                  <td>PLATA DER</td>
                  <td>MESA ALTA</td>
                  <td>1 a 4</td>
                  <td>$1,990</td>
                </tr>
                <tr>
                  <td>PLATA IZQ</td>
                  <td>MESA ALTA</td>
                  <td>1 a 4</td>
                  <td>$1,990</td>
                </tr>
                <tr>
                  <td>PREMIUM A</td>
                  <td>MESA VIP</td>
                  <td>1 a 8</td>
                  <td>$2,200</td>
                </tr>
                <tr>
                  <td>PREMIUM B</td>
                  <td>MESA ELEGANCE</td>
                  <td>1 a 4</td>
                  <td>$1,990</td>
                </tr>
                <tr>
                  <td>BRONCE A</td>
                  <td>MESA BAJA</td>
                  <td>1 a 5</td>
                  <td>$1,850</td>
                </tr>
                <tr>
                  <td>BRONCE B</td>
                  <td>MESA BAJA</td>
                  <td>1 a 4</td>
                  <td>$1,650</td>
                </tr>
                <tr>
                  <td>ROJA - AZUL</td>
                  <td>SILLA NUMERADA</td>
                  <td>1</td>
                  <td>$900 A $1,350</td>
                </tr>
                <tr class="website">
                  <td colspan="4">WWW.BOLETOMOVIL.COM</td>
                </tr>
              </tbody>
            </table>
          </div>
          <!--  -->

          <!-- Reili Barba -->
          <div class="table-responsive" v-if="modalEvent.eventNum == 3">
            <table class="table">
              <thead>
                <tr>
                  <th>Zona</th>
                  <th>Mobiliario</th>
                  <th>Opciones de lugares</th>
                  <th>Cada boleto</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>VIP STAGE</td>
                  <td>MESA VIP</td>
                  <td>8</td>
                  <td>$2,990</td>
                </tr>
                <tr>
                  <td>DIAMANTE</td>
                  <td>MESA VIP</td>
                  <td>1 a 8</td>
                  <td>$2,850</td>
                </tr>
                <tr>
                  <td>VIP BLACK</td>
                  <td>MESA VIP</td>
                  <td>1 a 10</td>
                  <td>$2,890</td>
                </tr>
                <tr>
                  <td>PLATINO IZQ</td>
                  <td>MESA VIP</td>
                  <td>1 a 8</td>
                  <td>$2,750</td>
                </tr>
                <tr>
                  <td>PLATINO DER</td>
                  <td>MESA VIP</td>
                  <td>1 a 8</td>
                  <td>$2,750</td>
                </tr>
                <tr>
                  <td>ORO A</td>
                  <td>MESA VIP</td>
                  <td>1 a 8</td>
                  <td>$2,590</td>
                </tr>
                <tr>
                  <td>ORO B</td>
                  <td>MESA VIP</td>
                  <td>1 a 8</td>
                  <td>$2,490</td>
                </tr>
                <tr>
                  <td>ORO C</td>
                  <td>MESA VIP</td>
                  <td>1 a 6</td>
                  <td>$2,290</td>
                </tr>
                <tr>
                  <td>PLATA DER</td>
                  <td>MESA ELEGANCE</td>
                  <td>1 a 2</td>
                  <td>$1,850</td>
                </tr>
                <tr>
                  <td>PLATA DER</td>
                  <td>MESA ALTA</td>
                  <td>1 a 4</td>
                  <td>$1,850</td>
                </tr>
                <tr>
                  <td>PLATA IZQ</td>
                  <td>MESA ALTA</td>
                  <td>1 a 4</td>
                  <td>$1,850</td>
                </tr>
                <tr>
                  <td>PREMIUM A</td>
                  <td>MESA VIP</td>
                  <td>1 a 8</td>
                  <td>$1,950</td>
                </tr>
                <tr>
                  <td>PREMIUM B</td>
                  <td>MESA ELEGANCE</td>
                  <td>1 a 4</td>
                  <td>$1,790</td>
                </tr>
                <tr>
                  <td>BRONCE A</td>
                  <td>MESA BAJA</td>
                  <td>1 a 5</td>
                  <td>$1,590</td>
                </tr>
                <tr>
                  <td>BRONCE B</td>
                  <td>MESA BAJA</td>
                  <td>1 a 4</td>
                  <td>$1,490</td>
                </tr>
                <tr>
                  <td>ROJA - AZUL</td>
                  <td>SILLA NUMERADA</td>
                  <td>1</td>
                  <td>$750 A $1,100</td>
                </tr>
                <tr class="website">
                  <td colspan="4">WWW.BOLETOMOVIL.COM</td>
                </tr>
              </tbody>
            </table>
          </div>
          <!--  -->

          <!-- Legacy World Tour -->
          <!-- <div class="table-responsive" v-if="modalEvent.eventNum == 4">
            <table class="table">
              <thead>
                <tr>
                  <th>Zona</th>
                  <th>Mobiliario</th>
                  <th>Opciones de lugares</th>
                  <th>Cada boleto</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>VIP STAGE</td>
                  <td>MESA VIP</td>
                  <td>8</td>
                  <td>$2,490</td>
                </tr>
                <tr>
                  <td>DIAMANTE</td>
                  <td>MESA VIP</td>
                  <td>1 a 8</td>
                  <td>$2,250</td>
                </tr>
                <tr>
                  <td>VIP BLACK</td>
                  <td>MESA VIP</td>
                  <td>1 a 10</td>
                  <td>$1,950</td>
                </tr>
                <tr>
                  <td>PLATINO IZQ</td>
                  <td>MESA VIP</td>
                  <td>1 a 8</td>
                  <td>$2,150</td>
                </tr>
                <tr>
                  <td>PLATINO DER</td>
                  <td>MESA VIP</td>
                  <td>1 a 8</td>
                  <td>$2,150</td>
                </tr>
                <tr>
                  <td>ORO A</td>
                  <td>MESA VIP</td>
                  <td>1 a 8</td>
                  <td>$1,900</td>
                </tr>
                <tr>
                  <td>ORO B</td>
                  <td>MESA VIP</td>
                  <td>1 a 8</td>
                  <td>$1,800</td>
                </tr>
                <tr>
                  <td>ORO C</td>
                  <td>MESA VIP</td>
                  <td>1 a 6</td>
                  <td>$1,790</td>
                </tr>
                <tr>
                  <td>PLATA DER</td>
                  <td>MESA ELEGANCE</td>
                  <td>1 a 2</td>
                  <td>$1,450</td>
                </tr>
                <tr>
                  <td>PLATA DER</td>
                  <td>MESA ALTA</td>
                  <td>1 a 4</td>
                  <td>$1,450</td>
                </tr>
                <tr>
                  <td>PLATA IZQ</td>
                  <td>MESA ALTA</td>
                  <td>1 a 4</td>
                  <td>$1,450</td>
                </tr>
                <tr>
                  <td>PREMIUM A</td>
                  <td>MESA VIP</td>
                  <td>1 a 8</td>
                  <td>$1,500</td>
                </tr>
                <tr>
                  <td>PREMIUM B</td>
                  <td>MESA ELEGANCE</td>
                  <td>1 a 4</td>
                  <td>$1,350</td>
                </tr>
                <tr>
                  <td>BRONCE A</td>
                  <td>MESA BAJA</td>
                  <td>1 a 5</td>
                  <td>$1,100</td>
                </tr>
                <tr>
                  <td>BRONCE B</td>
                  <td>MESA BAJA</td>
                  <td>1 a 4</td>
                  <td>$990</td>
                </tr>
                <tr>
                  <td>ROJA - AZUL</td>
                  <td>SILLA NUMERADA</td>
                  <td>1</td>
                  <td>$499 A $799</td>
                </tr>
                <tr class="website">
                  <td colspan="4">WWW.BOLETOMOVIL.COM</td>
                </tr>
              </tbody>
            </table>
          </div> -->
          <!--  -->

          <!-- Ricardo Montaner -->
          <div class="table-responsive" v-if="modalEvent.eventNum == 5">
            <table class="table">
              <thead>
                <tr>
                  <th>Zona</th>
                  <th>Mobiliario</th>
                  <th>Opciones de lugares</th>
                  <th>Cada boleto</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>VIP STAGE</td>
                  <td>MESA VIP</td>
                  <td>8</td>
                  <td>$3,950</td>
                </tr>
                <tr>
                  <td>DIAMANTE</td>
                  <td>MESA VIP</td>
                  <td>1 a 8</td>
                  <td>$3,450</td>
                </tr>
                <tr>
                  <td>VIP BLACK</td>
                  <td>MESA VIP</td>
                  <td>1 a 10</td>
                  <td>$3,350</td>
                </tr>
                <tr>
                  <td>PLATINO IZQ</td>
                  <td>MESA VIP</td>
                  <td>1 a 8</td>
                  <td>$3,350</td>
                </tr>
                <tr>
                  <td>PLATINO DER</td>
                  <td>MESA VIP</td>
                  <td>1 a 8</td>
                  <td>$3,350</td>
                </tr>
                <tr>
                  <td>ORO A</td>
                  <td>MESA VIP</td>
                  <td>1 a 8</td>
                  <td>$3,150</td>
                </tr>
                <tr>
                  <td>ORO B</td>
                  <td>MESA VIP</td>
                  <td>1 a 8</td>
                  <td>$2,850</td>
                </tr>
                <tr>
                  <td>ORO C</td>
                  <td>MESA VIP</td>
                  <td>1 a 6</td>
                  <td>$2,750</td>
                </tr>
                <tr>
                  <td>PLATA DER</td>
                  <td>MESA ELEGANCE</td>
                  <td>1 a 2</td>
                  <td>$2,350</td>
                </tr>
                <tr>
                  <td>PLATA DER</td>
                  <td>MESA ALTA</td>
                  <td>1 a 4</td>
                  <td>$2,350</td>
                </tr>
                <tr>
                  <td>PLATA IZQ</td>
                  <td>MESA ALTA</td>
                  <td>1 a 4</td>
                  <td>$2,350</td>
                </tr>
                <tr>
                  <td>PREMIUM A</td>
                  <td>MESA VIP</td>
                  <td>1 a 8</td>
                  <td>$2,550</td>
                </tr>
                <tr>
                  <td>PREMIUM B</td>
                  <td>MESA ELEGANCE</td>
                  <td>1 a 4</td>
                  <td>$2,350</td>
                </tr>
                <tr>
                  <td>BRONCE A</td>
                  <td>MESA BAJA</td>
                  <td>1 a 5</td>
                  <td>$1,950</td>
                </tr>
                <tr>
                  <td>BRONCE B</td>
                  <td>MESA BAJA</td>
                  <td>1 a 4</td>
                  <td>$1,800</td>
                </tr>
                <tr>
                  <td>ROJA - AZUL</td>
                  <td>SILLA NUMERADA</td>
                  <td>1</td>
                  <td>$650 A $950</td>
                </tr>
                <tr class="website">
                  <td colspan="4">WWW.BOLETOMOVIL.COM</td>
                </tr>
              </tbody>
            </table>
          </div>
          <!--  -->
        </div>
      </div>
    </b-modal>

  </div>
</template>

<script>
export default {
  data() {
    return {
      banners: {
        desktop: [
          { imageUrl: 'public/images/pages/home/banner-1.jpg' },
          // { imageUrl: 'public/images/pages/home/banner-2.jpg' },
          // { imageUrl: 'public/images/pages/home/banner-3.jpg' },
          // { imageUrl: 'public/images/pages/home/banner-4.jpg' },
          // { imageUrl: 'public/images/pages/home/banner-7.jpg' },
          // { imageUrl: 'public/images/pages/home/banner-6.jpg' },
          // { imageUrl: 'public/images/pages/home/banner-9.jpg' },
          // { imageUrl: 'public/images/pages/home/banner-10.jpg' },
          // { imageUrl: 'public/images/pages/home/banner-8.jpg' },
          // { imageUrl: 'public/images/pages/home/banner-5.jpg' },
          // { imageUrl: 'public/images/pages/home/banner-13.jpg' },
          // { imageUrl: 'public/images/pages/home/banner-12.jpg' },
          // { imageUrl: 'public/images/pages/home/banner-16.jpg' },
          // { imageUrl: 'public/images/pages/home/banner-11.jpg' },
          // { imageUrl: 'public/images/pages/home/banner-14.jpg' },
          // { imageUrl: 'public/images/pages/home/banner-17.jpg' },
          // { imageUrl: 'public/images/pages/home/banner-15.jpg' },
          { imageUrl: 'public/images/pages/home/banner-19.jpg' },
          { imageUrl: 'public/images/pages/home/banner-18.jpg' },
        ],

        mobile: [
          { imageUrl: 'public/images/pages/home/banner-1-m.jpg' },
          // { imageUrl: 'public/images/pages/home/banner-2-m.jpg' },
          // { imageUrl: 'public/images/pages/home/banner-3-m.jpg' },
          // { imageUrl: 'public/images/pages/home/banner-4-m.jpg' },
          // { imageUrl: 'public/images/pages/home/banner-7-m.jpg' },
          // { imageUrl: 'public/images/pages/home/banner-6-m.jpg' },
          // { imageUrl: 'public/images/pages/home/banner-9-m.jpg' },
          // { imageUrl: 'public/images/pages/home/banner-10-m.jpg' },
          // { imageUrl: 'public/images/pages/home/banner-8-m.jpg' },
          // { imageUrl: 'public/images/pages/home/banner-5-m.jpg' },
          // { imageUrl: 'public/images/pages/home/banner-13-m.jpg' },
          // { imageUrl: 'public/images/pages/home/banner-12-m.jpg' },
          // { imageUrl: 'public/images/pages/home/banner-16-m.jpg' },
          // { imageUrl: 'public/images/pages/home/banner-11-m.jpg' },
          // { imageUrl: 'public/images/pages/home/banner-14-m.jpg' },
          // { imageUrl: 'public/images/pages/home/banner-17-m.jpg' },
          // { imageUrl: 'public/images/pages/home/banner-15-m.jpg' },
          { imageUrl: 'public/images/pages/home/banner-19-m.jpg' },
          { imageUrl: 'public/images/pages/home/banner-18-m.jpg' },
        ]
      },

      modalEvent: {
        eventNum: null,
        imageUrl: null,
        videoUrl: null,
        name: null,
        url: null,
        date: null,
        hour: null,
        size: null,
      },

      events: [
        // {
        //   eventNum: 1,
        //   imageUrl: 'public/images/pages/home/event-2.jpg',
        //   videoUrl: 'https://www.youtube.com/shorts/WA7LwC6IzSk',
        //   available: true,
        //   place: 'SEDE STAGE SHOW CENTER',
        //   date: '1/Sep/2023',
        //   hour: '10:00 PM',
        //   name: 'Mariachi Vargas de Tecalitlán y Camila Fernandez',
        //   url: 'https://boletomovil.com/evento/mariachi-vargas-de-tecatitlan-y-camila-fernandez-2023-09-01?utm_source=web_sedestage&utm_medium=sitio_sedestage&utm_campaign=sitioweb_mariachivargas_sedestage',
        //   descr: '<p><span class="text-center"><strong>Un Mariachi de clase mundial.</strong></span><br />El Mariachi Vargas de Tecalitlán ha interpretado la música de México en cientos de escenarios alrededor del mundo, su inigualable talento los ha llevado a interpretar a lo largo del continente Americano, Europa y Asia, convirtiéndose en el máximo embajador musical de México a nivel global. Esta vez presentarán el tour “Mexicano hasta la raíz”. Además contaremos con la participación especial de Camila Fernández, quien presentará su show con Mariachi en donde presentará su más reciente tema titulado “Todo todo”.</p>',
        // },
        // {
        //   eventNum: 3,
        //   imageUrl: 'public/images/pages/home/event-3.jpg',
        //   videoUrl: 'https://www.youtube.com/watch?v=5SVLAFRu684',
        //   available: true,
        //   place: 'SEDE STAGE SHOW CENTER',
        //   date: '2/Sep/2023',
        //   hour: '9:45 PM',
        //   name: 'Reyli Barba y Río Roma',
        //   url: 'https://boletomovil.com/evento/reyli-barba-y-rio-roma-2023-09-02?utm_source=web_sedestage&utm_medium=sitio_sedestage&utm_campaign=sitioweb_reyli_rioroma_sedestage',
        //   descr: '<p>Un evento inolvidable se aproxima a Sede Stage, Reyli Barba y Río Roma dos grandes del pop latino en un mismo escenario, ven a cantarle al amor con tu persona favorita, más cerca de lo que te imaginas</p>',
        // },
        // {
        //   eventNum: 2,
        //   imageUrl: 'public/images/pages/home/event-1.jpg',
        //   videoUrl: 'https://www.youtube.com/watch?v=G8l3X3CD0Qk',
        //   available: true,
        //   place: 'SEDE STAGE SHOW CENTER',
        //   date: '9/Sep/2023',
        //   hour: '9:45 PM',
        //   name: 'Fiesta Sinaloense',
        //   url: 'https://boletomovil.com/evento/fiesta-sinaloense-2023-09-09?utm_source=web_sedestage&utm_medium=sitio_sedestage&utm_campaign=sitioweb_fiestasinaloense_sedestage',
        //   descr: '<p>Juntos por primera vez en escena El Coyote, Julio Preciado y Fidel Rueda se reunen para traerte lo mejor de la música de banda sinaloense, tres leyendas del género reunidos en un mismo escenario y tu, más cerca de lo que te imaginas.</p>',
        // },
        // {
        //   eventNum: 6,
        //   imageUrl: 'public/images/pages/home/event-6.jpg',
        //   videoUrl: null,
        //   available: true,
        //   place: 'SEDE STAGE SHOW CENTER',
        //   date: '15/Sep/2023',
        //   hour: '9:45 PM',
        //   name: 'Ven y grita con nosotros',
        //   url: null,
        //   descr: null,
        //   size: 'sm',
        // },
        // {
        //   eventNum: 7,
        //   imageUrl: 'public/images/pages/home/event-7.jpg',
        //   videoUrl: null,
        //   available: true,
        //   place: 'SEDE STAGE SHOW CENTER',
        //   date: '30/Sep/2023',
        //   hour: '8:00 PM',
        //   name: 'Transmisión en vivo de la pelea de Canero VS Jermell',
        //   url: null,
        //   descr: null,
        //   size: 'sm',
        // },
        // {
        //   eventNum: 5,
        //   imageUrl: 'public/images/pages/home/event-5.jpg',
        //   videoUrl: 'https://www.youtube.com/watch?v=C3Ljphz7-H8',
        //   available: true,
        //   place: 'SEDE STAGE SHOW CENTER',
        //   date: '14/Oct/2023',
        //   hour: '9:45 PM',
        //   name: 'Ricardo Montaner',
        //   url: 'https://boletomovil.com/evento/ricardo-montaner-2023-10-14?utm_source=web_sedestage&utm_medium=sitio_sedestage&utm_campaign=sitioweb_ricardomoontaner_sedestage',
        //   descr: '<p>El cantautor venezolano Ricardo Montaner, regresa a Guadalajara con su nueva gira internacional "Te Echo De Menos". Con "Tango", su última producción bajo el brazo y sus clásicos de siempre como "Tan Enamorados", "Bésame", "Me Va A Extrañar" y "El Poder De Tu Amor", Montaner promete una noche llena de nostalgia y romanticismo para el público mexicano.</p>',
        //   size: 'xl',
        // },
        // {
        //   eventNum: 9,
        //   imageUrl: 'public/images/pages/home/event-9.jpg',
        //   videoUrl: null,
        //   available: true,
        //   place: 'SEDE STAGE SHOW CENTER',
        //   date: '10/Nov/2023',
        //   hour: '8:00 PM',
        //   name: 'Box Televisa llega a Guadalajara con todas sus estrellas',
        //   url: null,
        //   descr: `<p>Una batalla México contra el mundo se apodera de Sede Stage Show Center en Guadalajara, con la disputa del campeonato Internacional WBC entre el olímpico mexicano 'Pivi' Romero y el invicto Miroshnichenko; con los comentarios del equipo de expertos de Box Televisa comandados por Carlos Aguilar 'El Zar' y Leonardo Riaño, además de las leyendas del cuadrilátero Ricardo 'Finito' López, Marco Antonio Barrera y Mariana 'La Barby' Juárez en su posición de privilegio junto al rombo de batalla.</p><br /><p>Venta de boletos a través del sistema Enterticket o en Taquillas del lugar.</p>`,
        //   size: 'sm',
        // },
        // {
        //   eventNum: 10,
        //   imageUrl: 'public/images/pages/home/event-10.jpg',
        //   videoUrl: null,
        //   available: true,
        //   place: 'SEDE STAGE SHOW CENTER',
        //   date: '25/Nov/2023',
        //   hour: '7:30 PM',
        //   name: 'Certamen de belleza Miss Jalisco y Río Roma en concierto',
        //   url: null,
        //   descr: '<p>Una noche inolvidable se aproxima a Sede Stage, el certamen de belleza Miss Jalisco 2023 y Río Roma en concierto, Prepárate para cantar éxitos como Mi persona favorita, Todavía no te olvido, Caminar de tu mano, entre otros, el próximo sábado 25 de Noviembre de 2023 en la nueva sede de los grandes Espectáculos SEDE STAGE.</p>',
        //   size: 'sm',
        // },
        // {
        //   eventNum: 8,
        //   imageUrl: 'public/images/pages/home/event-8.jpg',
        //   videoUrl: null,
        //   available: true,
        //   place: 'SEDE STAGE SHOW CENTER',
        //   date: '02/Dic/2023',
        //   hour: '9:45 PM',
        //   name: 'Viral Fight',
        //   url: 'https://boletomovil.com/evento/viral-fight-2023-12-02',
        //   descr: '<p>Viral Fight es el primer evento de MMA en México con peleas de Influencers en Vivo<br /><br />Figuras públicas, streamers y peleadores profesionales se reúnen en el octágono de Sede Stage en Guadalajara y todo se decidirá el 02 de Diciembre.<br /><br />Compra tu entrada en boletomovil.com<br /><br />Próximamente apuéstale a tu favorito en teammexico.mx</p>',
        //   size: 'sm',
        // },
        // {
        //   eventNum: 4,
        //   imageUrl: 'public/images/pages/home/event-4.jpg',
        //   videoUrl: null,
        //   available: true,
        //   place: 'SEDE STAGE SHOW CENTER',
        //   date: '16/Feb/2024',
        //   hour: '10:00 PM',
        //   name: 'GDL Legacy Tour',
        //   url: 'https://boletomovil.com/evento/legacy-world-tour-2024-02-16',
        //   descr: '<p>Este viernes 16 de febrero regresa a GDL Legacy Tour ¡ Más que un homenaje a Michael Jackson! Sin imitadores By Reakcion Brothers, no te pierdas el concierto más exclusivo sobre el rey del pop, con los músicos y coreógrafos originales que colaboraron con el rey del pop por más de 20 años, un espectáculo único de canto, baile, coreografías, break dance y beat-box nunca antes visto. Reviviendo grandes éxitos de los tours más importantes junto a los increíbles músicos que lo acompañaron en su gira, con más de 30 artistas en escena, es la energía de Michael en concierto.</p><br /><p>Después del show, pásala increíble en el affter party con un dj invitado sorpresa música de los 80’s, 90’s y actual. Solo en Sede Stage Show Center ¡No te lo pierdas!</p>',
        //   size: 'sm',
        // },
        // {
        //   eventNum: 13,
        //   imageUrl: 'public/images/pages/home/event-13.jpg',
        //   videoUrl: null,
        //   available: true,
        //   place: 'SEDE STAGE SHOW CENTER',
        //   date: '8/Marzo/2024',
        //   hour: '10:00 PM',
        //   name: 'El Fantasma',
        //   url: 'https://boletomovil.com/evento/el-fantasma-2024-03-08',
        //   descr: `<p>
        //     Alexander García, mejor conocido como “El Fantasma”, es un artista que ha sabido llevar la música regional mexicana a niveles Internacionales. En sus más de siete años de carrera ha sido acreedor de grandes premios de la Industria Musical y logrado posicionarse en los primeros lugares de medios de comunicación y plataformas digitales con cada uno de sus lanzamientos, gracias a que sus letras mantienen vivo el ritmo tradicional de la musica mexicana. Con banda sinaloense, mariachi, sierreño y norteño, logra cautivar a su público en cada uno de sus shows en vivo.<br />
        //     <br />
        //     Actualmente se posiciona dentro del primer lugar de radio a nivel nacional con su más reciente sencillo “El Éxito” tema dedicado a todas aquellas personas que han estado junto a ti en cada uno de tus logros. <br />
        //     <br />
        //     Este 08 de marzo llega a Sede Stage con su gira musical 2024 titulada “Se Activo La Chavala” ¡No te lo puedes perder!
        //   `,
        //   size: 'sm',
        // },
        // {
        //   eventNum: 12,
        //   imageUrl: 'public/images/pages/home/event-12.jpg',
        //   videoUrl: null,
        //   available: true,
        //   place: 'SEDE STAGE SHOW CENTER',
        //   date: '16/Marzo/2024',
        //   hour: '9:45 PM',
        //   name: 'Marisela, "La dama de Hierro"',
        //   url: 'https://boletomovil.com/evento/marisela-la-dama-de-hierro-2024-03-16',
        //   descr: `<p>
        //     Marisela, "La dama de Hierro" regresa a Guadalajara, World 2024 Tour “Con Amor” Disfrútala en un concierto más íntimo y de mayor experiencia en SEDE STAGE SHOW CENTER, con todos sus grandes éxitos; donde interpretará lo mejor de su repertorio, como "sin él", "mi problema", "a cambio de ¿qué?", además del emblemático "tu dama de hierro", serán algunos de los temas con los que celebra casi 4 décadas de música en el país, que le dio el nombre de Marisela y que la vio nacer como artista.
        //   </p>`,
        //   size: 'sm',
        // },
        // {
        //   eventNum: 16,
        //   imageUrl: 'public/images/pages/home/event-16.jpg',
        //   videoUrl: null,
        //   available: true,
        //   place: 'SEDE STAGE SHOW CENTER',
        //   date: '26/Abril/2024',
        //   hour: '10:00 PM',
        //   name: 'Hugel, Tom & Collins, Macarena, Carlo Cobos y Catarsis',
        //   url: 'https://boletomovil.com/evento/terms-and-conditions-2024-04-26',
        //   descr: `<p>
        //     El showcase de la disquera terms & conditions se ha presentado en las ciudades más emblemáticas de la escena y se ha destacado por su house que te envuelve en una experiencia con los más finos Beats del dúo tom & collins con invitados de talla internacional. En esta ocasión aterriza en Guadalajara con los invitados Hugel, Macarena, Carlo Cobos y Catarsis, el próximo Viernes 26 de Abril del 2024.
        //   </p>`,
        //   size: 'sm',
        // },
        // {
        //   eventNum: 11,
        //   imageUrl: 'public/images/pages/home/event-11.jpg',
        //   videoUrl: null,
        //   available: true,
        //   place: 'SEDE STAGE SHOW CENTER',
        //   date: '27/Abril/2024',
        //   hour: '9:30 PM',
        //   name: 'La Onda Vaselina en Concierto',
        //   url: 'https://boletomovil.com/evento/la-onda-vaselina-el-origen-2024-04-27',
        //   descr: `<p>
        //     Ven a recordar, cantar, bailar y vivir las canciones de cuando éramos niños.<br />
        //     <br />
        //     Un espectáculo de Julissa, "Celebrando 35 Años del Origen” de este concepto musical que nos ha marcado a generaciones. <br />
        //     <br />
        //     La cita es este sábado 27 de Abril en SEDE Stage GUADALAJARA a las 21:30 hrs. <br />
        //     ¡No te pierdas la experiencia!<br />
        //     <br />
        //     #TodosSomosLaOndaVaselina
        //   </p>`,
        //   size: 'sm',
        // },
        // {
        //   eventNum: 14,
        //   imageUrl: 'public/images/pages/home/event-14.jpg',
        //   videoUrl: null,
        //   available: true,
        //   place: 'SEDE STAGE SHOW CENTER',
        //   date: '10/Mayo/2024',
        //   hour: '9:45 PM',
        //   name: 'Kany García',
        //   url: 'https://boletomovil.com/evento/kany-garcia-2024-05-10',
        //   descr: `<p>
        //     Kany García la cantautora puertorriqueña sigue enamorando al mundo con su música, y ahora, tras las exitosas presentaciones en múltiples escenarios a través de Latinoamérica llega a Guadalajara a SEDE STAGE SHOW CENTER con su tour 2024 en donde interpretará sus más grandes éxitos como: “La Siguiente” “Te lo Agradezco” “Confieso” “DPM” y más, disfruta este gran concierto en el nuevo centro de espectáculos al sur de la ciudad.
        //   </p>`,
        //   size: 'sm',
        // },
        // {
        //   eventNum: 17,
        //   imageUrl: 'public/images/pages/home/event-17.jpg',
        //   videoUrl: null,
        //   available: true,
        //   place: 'SEDE STAGE SHOW CENTER',
        //   date: '14/Junio/2024',
        //   hour: '10:00 PM',
        //   name: 'I LOVE DANCE',
        //   url: 'https://fullpass.mx/515livetalent/es/comprarEvento?idEvento=92',
        //   descr: `<p>
        //     El festival que reúne a los íconos más grandes del Dance mundial llega en 2024 a Ciudad de México y a Guadalajara.<br />
        //     <br />
        //     Dale CLIC <a target="_blank" href="https://bit.ly/SEDESTAGE360">AQUÍ</a> sí aún no conoces SEDE STAGE y descubre un nuevo centro de espectáculos en Guadalajara.
        //   </p>`,
        //   size: 'sm',
        // },
        {
          eventNum: 19,
          imageUrl: 'public/images/pages/home/event-19.jpg',
          videoUrl: null,
          available: true,
          place: 'SEDE STAGE SHOW CENTER',
          date: '15/Marzo/2025',
          hour: '7:30 PM',
          name: 'Miss Jalisco, Gran Final',
          url: 'https://www.ticketmaster.com.mx/miss-jalisco-2025-tlajomulco-de-zunija-15-03-2025/event/3D006259B0614418',
          descr: null,
          size: 'sm',
        },
        {
          eventNum: 18,
          imageUrl: 'public/images/pages/home/event-18.jpg',
          videoUrl: null,
          available: true,
          place: 'SEDE STAGE SHOW CENTER',
          date: '22/Marzo/2025',
          hour: '9:00 PM',
          name: 'Roberto Tapia',
          url: 'https://www.ticketmaster.com.mx/roberto-tapia-tlajomulco-de-zunija-22-03-2025/event/3D006255C547447D',
          descr: null,
          size: 'sm',
        },
      ],

      sponsors: [
        { imageUrl: 'public/images/pages/home/logo-1.png' },
        // { imageUrl: 'public/images/pages/home/logo-2.png' },
        { imageUrl: 'public/images/pages/home/logo-3.png' },
        //{ imageUrl: 'public/images/pages/home/logo-2.png' },
        //{ imageUrl: 'public/images/pages/home/logo-3.png' },
        // { imageUrl: 'public/images/pages/home/logo-4.png' },
      ],

      // == Carousel options ==
      bannersOptions: {
        effect: 'fade',
        loop: true,
        speed: 700,

        autoplay: {
          delay: 4000,
          disableOnInteraction: false
        },

        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      },

      sponsorsOptions: {
        slidesPerView: 2,
        spaceBetween: 10,

        autoplay: {
          delay: 200000,
          disableOnInteraction: false
        },

        breakpoints: {
          1200: {
            slidesPerView: 2,
            spaceBetween: 10,

            autoplay: {
              delay: 200000,
              disableOnInteraction: false
            },
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 30,

            autoplay: {
              delay: 200000,
              disableOnInteraction: false
            },
          },
          1: {
            slidesPerView: 1,
            spaceBetween: 30,

            autoplay: {
              delay: 4000,
              disableOnInteraction: false
            },
          },
        },

        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        }
      }
      // == ==
    }
  },

  methods: {
    convertVideoID (url) {
      if(url) {
        var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(shorts\/)|(watch\?))\??v?=?([^#&?]*).*/;
        var match = url.match(regExp);
        var ytBaselink = 'https://www.youtube.com/embed/';
        var ytID = (match&&match[8].length==11)? match[8] : false;
        return ytBaselink + ytID + '?rel=0';
      }
    },

    showEventModal(idx) {
      this.modalEvent = this.events[idx];
      this.$refs['modal-event'].show();
    },
  },

  mounted() {
  }
}
</script>
