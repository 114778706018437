<template lang="html">
  <div id="contact-page">

    <section class="main-section">
      <div class="container">
        <div class="row">
          <div class="col-lg-9">
            <div class="box-form">
              <h1 class="title">¡Contáctanos!</h1>

              <div class="d-block mt-3 text-center">
                <h6 class="f-w-600" style="font-size: 1.12rem;">¿Cómo podemos ayudarte?</h6>
                <p class="mt-1">
                  Mándanos tu información o envíanos un mensaje y nos pondremos en contacto contigo, tan pronto como sea posible.
                </p>
              </div>

              <b-form @submit="onSubmit">
                <b-form-group class="custom-group-s1">
                  <b-form-input
                    v-model="form.email"
                    type="email"
                    placeholder="Correo electrónico"
                    required
                  ></b-form-input>
                </b-form-group>

                <div class="row">
                  <div class="col-md-8 pr-md-0">
                    <b-form-group class="custom-group-s1">
                      <b-form-input
                        v-model="form.name"
                        type="text"
                        placeholder="Nombre completo"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </div>

                  <div class="col-md-4">
                    <b-form-group class="custom-group-s1">
                      <b-form-input
                        v-model="form.phone"
                        type="text"
                        placeholder="Teléfono"
                        minlength="10"
                        maxlength="10"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </div>

                  <div class="col-12">
                    <b-form-group class="custom-group-s1">
                      <b-form-textarea
                        v-model="form.msg"
                        placeholder="Escribe un mensaje"
                        rows="5"
                        max-rows="5"
                        required
                      ></b-form-textarea>
                    </b-form-group>
                  </div>

                  <div class="col-12 text-center">
                    <b-button type="submit" class="t-400 btn-send">Enviar formulario</b-button>
                  </div>
                </div>

              </b-form>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="map-section">
      <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14943.199087286603!2d-103.466195!3d20.5553718!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428adf0ab423ceb%3A0xf5116bf611fbcc99!2sSEDE%20STAGE%20SHOW%20CENTER!5e0!3m2!1ses-419!2smx!4v1689369551670!5m2!1ses-419!2smx" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        name: null,
        lastName: null,
        email: null,
        phone: null,
        msg: null,
      }
    }
  },

  methods: {
    makeToast(variant = null, msg, title) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
        toaster: 'b-toaster-bottom-right',
        appendToast: true
      })
    },

    onSubmit(event) {
      event.preventDefault();

      var params = this.form;
      var apiURL = tools.url("/api/contact");

      axios.post( apiURL,params )
      .then( (response) => {
        // alert(response.data.response);
        this.makeToast('success', response.data.response, 'Mensaje enviado');
        // alert(response.data.response);

        for (let prop in this.form) {
          this.form[prop] = '';
        }
      })
      .catch( (error) => {
        // console.log(error.response.data);
        let errors = error.response.data;

        for (let prop in errors) {
          // console.log(errors[prop]);
          this.makeToast('danger', errors[prop], '¡Error!');
        }
      });
    },
  }
}
</script>
