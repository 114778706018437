<template lang="html">
  <div id="header">
    <div class="header-content">

      <!-- Payments -->
      <div class="box-buy" v-if="false">
        <div class="container d-flex align-items-center">
          <!-- <div class="col col-lg-3 col-xl col-ml">
            <h5>Protected and encrypted purchases</h5>
          </div> -->
          <!-- <div class="col col-lg-3 col-xl col-ssl">
            <img src="public/images/shared/ssl1.png">
            <span>A 246 bits</span>
          </div> -->
          <div class="col-lg-12 col-logos">
            <span>COMPRA TUS BOLETOS DE MANERA SEGURA</span>
            <i class="fab fa-cc-visa icon"></i>
            <i class="fab fa-cc-mastercard icon"></i>
            <i class="fab fa-cc-amex icon"></i>
            <!-- <img class="img-icon" src="public/images/shared/oxxo.png" alt="Oxxo"> -->
          </div>
        </div>
      </div>
      <!--  -->

      <!-- Navbar -->
      <b-navbar toggleable="lg" type="light" variant="default">
        <div class="container oversized-container">

          <b-navbar-brand to="/">
            <img class="logo" src="public/images/logo.svg">
          </b-navbar-brand>

          <b-navbar-toggle target="nav-collapse"><i class="fal fa-bars"></i></b-navbar-toggle>

          <b-collapse id="nav-collapse" class="header-navigation" is-nav>
            <b-navbar-nav class="ml-auto header-menu">
              <!-- <li class="nav-item networks-item">
                <div class="nav-link">
                  <a class="btn-network" target="_blank" :href="$root.ig"><i class="fab fa-instagram"></i></a>
                  <a class="btn-network" target="_blank" :href="$root.fb"><i class="fab fa-facebook-f"></i></a>
                  <a class="btn-network" target="_blank" :href="$root.tt"><i class="fab fa-tiktok"></i></a>
                </div>
              </li> -->
              <li class="nav-item video-item">
                <a class="nav-link" @click="$refs['modal-video'].show();"><i class="fas fa-play"></i></a>
              </li>
              <!-- <b-nav-item to="/">Proximos eventos</b-nav-item> -->
              <!-- <b-nav-item to="/">Comunicados</b-nav-item> -->
              <!-- <b-nav-item-dropdown right>
                <template slot="button-content">
                  <span>Sobre nosotros</span>
                </template>
                <b-dropdown-item to="/acerca-de">Acerca de</b-dropdown-item>
                <b-dropdown-item to="">Instalaciones</b-dropdown-item>
                <b-dropdown-item to="">Patrocina en Sede Stage</b-dropdown-item>
                <b-dropdown-item to="">Tu evento en Sede Stage</b-dropdown-item>
                <b-dropdown-item to="">Productos</b-dropdown-item>
              </b-nav-item-dropdown> -->
              <b-nav-item-dropdown right>
                <template slot="button-content">
                  <span>Nosotros</span>
                </template>
                <b-dropdown-item to="empresa">Empresa</b-dropdown-item>
                <b-dropdown-item to="/preguntas-frecuentes">Preguntas frecuentes</b-dropdown-item>
                <b-dropdown-item to="zonas-y-mesas">Zonas y mesas</b-dropdown-item>
              </b-nav-item-dropdown>

              <b-nav-item to="/galeria">Galería</b-nav-item>
              <b-nav-item to="/contacto">Contacto</b-nav-item>
              <b-nav-item class="orange-item" target="_blank" href="public/pdfs/MENU-SEDE-STAGE.pdf"><i class="far fa-glass-cheers"></i> Menú</b-nav-item>
              <li class="nav-item orange-item">
                <a class="nav-link" target="_blank" href="https://goo.gl/maps/pMZ9W5Ze2EyQHPBb7"><i class="far fa-map-marker-alt mr-1"></i> Cómo llegar</a>
              </li>
              <!-- <li class="nav-item my-1 my-lg-0 orange-item-btn">
                <a class="nav-link" target="_blank" @click="$refs['modal-boletin'].show()"><i class="far fa-newspaper mr-1"></i> Boletin</a>
              </li> -->
              <li class="nav-item my-1 my-lg-0 orange-item-btn">
                <a class="nav-link" target="_blank" href="https://maps.app.goo.gl/?link=https://www.google.com/maps/@20.5553722,-103.4661942,0a,90y,312.39h,96.65t/data%3D!3m4!1e1!3m2!1sAF1QipOg0g2NFKoFnxE9Nc--tCJgmuYtDpUZCaWhFBSL!2e10?utm_source%3Dmstt_0%26g_ep%3DCAESCjExLjEyMC4xMDIYACCBgQEqJCw5NDIwNzM2OSw0NzA3NTkxNSw5NDIwNzM4OCw0NzA4NDM5M0ICTVg%253D&apn=com.google.android.apps.maps&afl=https://www.google.com/maps/@20.5553722,-103.4661942,0a,90y,312.39h,96.65t/data%3D!3m4!1e1!3m2!1sAF1QipOg0g2NFKoFnxE9Nc--tCJgmuYtDpUZCaWhFBSL!2e10?utm_source%3Dmstt_0&isi=585027354&ibi=com.google.Maps&ius=comgooglemapsurl&ifl=https://www.google.com/maps/@20.5553722,-103.4661942,0a,90y,312.39h,96.65t/data%3D!3m4!1e1!3m2!1sAF1QipOg0g2NFKoFnxE9Nc--tCJgmuYtDpUZCaWhFBSL!2e10?utm_source%3Dmstt_0&cid=4775922135207621267&_osl=https://maps.app.goo.gl/hL27PZm81DN9uBG68&_icp=1"><i class="fas fa-street-view mr-1"></i> Tour Virtual</a>
              </li>

              <!-- <li class="nav-item simple-item sep-item">
                <div class="nav-link">
                  <i></i>
                </div>
              </li>

              <b-nav-item-dropdown right>
                <template slot="button-content">
                  <span><i class="fal fa-user"></i> Mi cuenta</span>
                </template>
                <b-dropdown-item to="">Iniciar sesión</b-dropdown-item>
                <b-dropdown-item to="">Registrarse</b-dropdown-item>
              </b-nav-item-dropdown> -->
            </b-navbar-nav>
          </b-collapse>

        </div>
      </b-navbar>
      <!--  -->

    </div>

    <b-modal modal-class="modal-video" ref="modal-video" size="lg" title="" hide-footer centered>
      <video poster="public/images/posterimage.jpg" controls>
        <source src="public/videos/video.mp4" type="video/mp4">
      </video>
    </b-modal>

    <b-modal modal-class="modal-form" ref="modal-boletin" title="¡Deja tu info y te enviaremos información y promos de los mejores eventos!" hide-footer centered>
      <b-form @submit="onSubmit">
        <b-form-group class="custom-group-s2"
          label="Nombre:">
          <b-form-input
            v-model="form.name"
            type="text"
            placeholder="Su nombre completo"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group class="custom-group-s2"
          label="Correo:">
          <b-form-input
            v-model="form.email"
            type="email"
            placeholder="Su correo electrónico"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group class="custom-group-s2"
          label="¿Qué genero te gusta?:">
          <b-form-select v-model="form.music" required class="mb-3">
            <b-form-select-option :value="null">Seleccione una opción</b-form-select-option>
            <b-form-select-option value="Alternativo">Alternativo</b-form-select-option >
            <b-form-select-option value="Conferencias">Conferencias</b-form-select-option >
            <b-form-select-option value="Cultural">Cultural</b-form-select-option >
            <b-form-select-option value="Danza / Ballet">Danza / Ballet</b-form-select-option >
            <b-form-select-option value="Infantil o familiar">Infantil o familiar</b-form-select-option >
            <b-form-select-option value="Pop">Pop</b-form-select-option >
            <b-form-select-option value="Regional Mexicano">Regional Mexicano</b-form-select-option >
            <b-form-select-option value="Rock">Rock</b-form-select-option >
            <b-form-select-option value="Electrónica">Electrónica</b-form-select-option >
              <b-form-select-option value="Otro">Otro</b-form-select-option >
          </b-form-select>
        </b-form-group>

        <div class="col-12 px-0">
          <b-button type="submit" class="btn-s1 w-100"  id="btnsave">Suscribirse</b-button>
        </div>
      </b-form>
    </b-modal>

    <div class="fixed-networks-buttons">
      <a class="btn-network t-150 ig" target="_blank" :href="$root.ig"><i class="fab fa-instagram"></i></a>
      <a class="btn-network t-150 fb" target="_blank" :href="$root.fb"><i class="fab fa-facebook-f"></i></a>
      <a class="btn-network t-150 tt" target="_blank" :href="$root.tt"><i class="fab fa-tiktok"></i></a>
      <a class="btn-network t-150 wh" target="_blank" :href="$root.wh"><i class="fab fa-whatsapp"></i></a>
      <a class="btn-network t-150 yt" target="_blank" :href="$root.yt"><i class="fab fa-youtube"></i></a>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      form: {
        name: null,
        music: null,
        musicGenre: null,
      }
    }
  },

  methods:{
    onSubmit(event) {
      event.preventDefault();

      axios.post(tools.url("/api/newsletter"),this.form).then((response)=>{
          $( "#btnsave" ).prop( "disabled", false );
          alert("Información registrada correctamente");
          this.$refs['modal-boletin'].hide();

			}).catch((error)=>{
					alert('Ocurrio un error al guardar la información');
				  $( "#btnsave" ).prop( "disabled", false );
			});
    },

    showVideoHome() {
      if(this.$route.name == 'homePage') {
        this.$refs['modal-video'].show();
      }
    },
  },

  mounted() {
    this.showVideoHome();
  },
}
</script>
