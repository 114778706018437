require('./bootstrap');

import Vue from 'vue';
//Rutas del website
import Router from './router.js';

//Librerias globales
import Library from './libs.js';
Vue.use(Library);

//Componentes del website
import components from './components/components.js';
Vue.use(components);

window.Vue=Vue;

//Instancia principal
const app = new Vue({
  el: '#app',
  router:Router,

  data() {
    return {
      ig: 'https://www.instagram.com/sede_stage/',
      fb: 'https://www.facebook.com/sedestagemx',
      tt: 'https://www.tiktok.com/@sedestage',
      wh: 'https://wa.me/5213333179287',
      yt: 'https://www.youtube.com/@sedestage4841',
    }
  }
});
