<template lang="html">
  <div id="text-page">
    <div class="container">
      <h1 class="mb-3 page-title">Preguntas frecuentes</h1>

      <h5>¿Cuál es la modalidad de compra de las mesas?</h5>
      <p>
        Puedes adquirir en cualquier mesa de cualquier zona desde 1 boleto, excepto en VIP STAGE se tiene que comprar la mesa completa.
      </p>
      <br />

      <h5>¿Requiero imprimir mis boletos electrónicos?</h5>
      <p>
        No, puedes ingresar con tu boleto digital en tu teléfono siempre y cuando el código sea legible.
      </p>
      <br />

      <h5>¿Hay requisito de edad?</h5>
      <p>
        Pueden ingresar desde los 15 años en adelante. Los menores de edad que vayan deben ir acompañados de un adulto que debe ser su familiar y comprobarlo en el ingreso por el acceso A Puerta 1.
      </p>
      <br />

      <h5>¿A partir de qué hora puedo ingresar al evento?</h5>
      <p>
        A partir de las 9:00 pm.
      </p>
      <br />

      <h5>¿Dónde puedo facturar la compra de mis boletos?</h5>
      <p>
        Para facturar tus boletos adquiridos en línea, por favor ingresa a la liga indicada en tu correo de confirmación de compra con tu código de facturación.
      </p>
      <br />

      <h5>¿En dónde puedo estacionar mi vehículo?</h5>
      <p>
        Puedes estacionarte en la plaza Galerías Santa Anita que se encuentra a un costado, contamos con tarifa preferencial, si compraste mesa VIP que incluye valet parking te haremos llegar tu boleto digital.
      </p>
      <br />

      <h5>¿Hasta qué hora cierra el lugar?</h5>
      <p>
        Una vez que termine el show del artista o los artistas, la fiesta continua hasta la madrugada, tenemos DJ y Happenings para hacer de tu noche la mejor, vive la experiencia.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      content: '',
    }
  },

  methods: {
    getContent: function(){
      axios.get(tools.url('/privacidad')).then((response)=>{
        this.content = response.data;;
      }).catch((error)=>{
        console.log(error);
      })
    }
  },

  beforeMount(){
    // this.getContent();
  }
}
</script>
