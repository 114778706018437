<template>
	<footer id="footer">
		<div class="container oversized-container">
			<div class="row">
				<!-- <div class="col-lg-3 col-logo"> -->
				<div class="col-lg-12 text-center col-logo">
					<router-link to="/">
						<img src="public/images/logo.png" alt="SEDE STAGE">
					</router-link>
				</div>

				<!-- <div class="col-lg-9 col-content"> -->
				<div class="col-12 mt-1 mt-lg-4 col-content">
					<div class="row">
						<div class="col-12 text-center col-contact">
							<!-- <span class="txt-contact"><i class="fal fa-comment icon"></i> (89) 6736 4873</span> -->
							<span class="ml-4 txt-contact">contacto@sedestage.com</span>
							<br />
							<span class="ml-4 networks">
								<a class="btn-network" target="_blank" :href="$root.ig"><i class="fab fa-instagram"></i></a>
								<a class="btn-network" target="_blank" :href="$root.fb"><i class="fab fa-facebook-f"></i></a>
								<a class="btn-network" target="_blank" :href="$root.tt"><i class="fab fa-tiktok"></i></a>
								<a class="btn-network" target="_blank" :href="$root.wh"><i class="fab fa-whatsapp"></i></a>
								<a class="btn-network" target="_blank" :href="$root.yt"><i class="fab fa-youtube"></i></a>
							</span>
						</div>

						<!-- <div class="col-6 col-lg-3 col-footer">
							<h5 class="title">INICIO</h5>

							<p><router-link to="">Cerca de ti</router-link></p>
							<p><router-link to="">Eventos</router-link></p>
							<p><router-link to="">Categorías</router-link></p>
							<p><router-link to="">Consumibles</router-link></p>
						</div> -->

						<!-- <div class="col-6 col-lg-3 col-footer">
							<h5 class="title">SOBRE NOSOTROS</h5>

							<p><router-link to="">Comunicados</router-link></p>
							<p><router-link to="">Nuestra historia</router-link></p>
							<p><router-link to="">Misión y valores</router-link></p>
							<p><router-link to="">Recintos</router-link></p>
						</div> -->

						<!-- <div class="col-6 col-lg-3 col-footer">
							<h5 class="title">CONTACTO</h5>

							<p><router-link to="">Redes sociales</router-link></p>
							<p><router-link to="">Ubicaciones</router-link></p>
							<p><router-link to="">Alianzas</router-link></p>
							<p><router-link to="/contacto">Contacto</router-link></p>
						</div> -->

						<div class="col-12 ml-auto text-center col-footer">
							<!-- <h5 class="title">SOPORTE</h5> -->

							<p>
								Av. Adolfo López Mateos Sur 9020-A, 45645 San Agustín, Jalisco
							</p>
							<p><router-link class="mt-1" to="/aviso-de-privacidad">Aviso de privacidad</router-link></p>
							<p><router-link class="mt-1" to="/reglamento">Reglamento</router-link></p>
							<p><a class="mt-2 link-map" target="_blank" href="https://goo.gl/maps/pMZ9W5Ze2EyQHPBb7"><i class="far fa-map-marker-alt"></i> ¿Cómo llegar?</a></p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</footer>
</template>

<script type="text/javascript">
export default {
}
</script>
